import React, { useState, useEffect } from 'react';
import Form from '../Form';
import ThankYou from '../ThankYou';
import { useMyBunac } from '../../../hooks/useMyBunac';
import { FIELD_TYPES, DATA_MODELS } from '../Form/Field';

const FORM_ID = 'soft_application';
const PIPELINE_ID = 3;

const formConfig = [
  {
    label: "What's your name?",
    name: 'Name',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: "What's your email address?",
    name: 'Email',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: "What's your phone number?",
    name: 'Phone',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: "What's your home country?",
    name: 'Country of Residence',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: "What's your preferred currency?",
    name: 'Preferred Currency',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'What program are you interested in?',
    name: 'Programme',
    isRequired: true,
    model: DATA_MODELS.deal,
  },
  {
    label:
      'Intern in Britain placement provider (only if booking Intern in Britain)',
    name: 'Supplier',
    isRequired: false,
    model: DATA_MODELS.deal,
  },
  {
    type: FIELD_TYPES.lineOfText,
    style: {
      marginTop: '10px',
      marginBottom: '10px',
    },
    label: (
      <p>
        BUNAC will only use these details to contact you about your work abroad
        booking. Please see the{' '}
        <a href="/privacy" target="_blanc">
          <b>BUNAC privacy policy</b>
        </a>{' '}
        for more information. You can manage your marketing communication
        preferences below.
      </p>
    ),
  },
  {
    label: 'Can we contact you via email?',
    name: 'Communication Consent Email',
    model: DATA_MODELS.person,
  },
  {
    label: 'Can we contact you via SMS?',
    name: 'Communication Consent SMS',
    model: DATA_MODELS.person,
  },
  {
    label: 'Can we contact you via phone?',
    name: 'Communication Consent Phone',
    model: DATA_MODELS.person,
  },
  {
    label:
      'To give you the best online customer experience, we would like to pass your data onto third parties such as Facebook and Instagram. Are you OK for us to pass your data onto third parties?',
    name: 'Communication Consent Social',
    model: DATA_MODELS.person,
  },
];

const SoftApplication = () => {
  const [submitted, setSubmited] = useState(false);
  const { profile } = useMyBunac();
  const [initValues, setInitValues] = useState({});
  const [defaultProgrammeId, setDefaultProgrammeId] = useState();

  useEffect(() => {
    const queryParams = {};
    const keyPairs = window.location.search.substr(1).split('&');
    for (const key in keyPairs) {
      var split = keyPairs[key].split('=');
      queryParams[decodeURIComponent(split[0])] = decodeURIComponent(split[1]);
    }

    if (!queryParams.programmeid) {
      return;
    }

    setDefaultProgrammeId(queryParams.programmeid);
  }, []);

  useEffect(() => {
    if (!profile) {
      return setInitValues({});
    }
    setInitValues({
      ...profile,
      phone: profile.phone ? profile.phone[0].value : undefined,
      email: profile.email ? profile.email[0].value : undefined,
      b0975083fc62b12746c82176eda3826150cf68b0: defaultProgrammeId || undefined,
    });
  }, [profile, defaultProgrammeId]);

  const submit = () => {
    setSubmited(true);
  };

  if (submitted) {
    return (
      <ThankYou
        title="Thanks for applying!"
        text="Thanks for choosing your BUNAC programme, click below to continue to login in or sign up to the MyBUNAC account to complete your booking."
        button="Go to MyBunac"
      />
    );
  }

  return (
    <Form
      headline="Apply for a Programme"
      formId={FORM_ID}
      pipelineId={PIPELINE_ID}
      config={formConfig}
      initValues={initValues}
      onSubmit={submit}
    />
  );
};

export default SoftApplication;